import { createContext, FC, useMemo, useState } from 'react';

type ContactModalApi = {
  open: boolean;
  openModal: () => void;
  closeModal: () => void;
};

export const ContactModalContext = createContext<ContactModalApi>({
  open: false,
  openModal: () => {},
  closeModal: () => {},
});

export const ContactModalProvider: FC = ({ children }) => {
  const [open, setOpen] = useState(false);

  const api = useMemo(
    () => ({
      open,
      openModal: () => setOpen(true),
      closeModal: () => setOpen(false),
    }),
    [open, setOpen],
  );

  return (
    <ContactModalContext.Provider value={api}>
      {children}
    </ContactModalContext.Provider>
  );
};
