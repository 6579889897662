import type { AppProps } from 'next/app';
import Head from 'next/head';
import { ContactModalProvider } from '@core/contexts/ContactModalContext';
import { Fragment } from 'react';
import { ToastContainer } from 'react-toastify';
import GlobalStyle from '../styles/global';
import 'react-toastify/dist/ReactToastify.css';

function App({ Component, pageProps }: AppProps) {
  return (
    <Fragment>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=5, viewport-fit=cover"
        />
      </Head>

      <GlobalStyle />

      <ContactModalProvider>
        <Component {...pageProps} />

        <ToastContainer
          autoClose={5000}
          position="top-right"
          closeOnClick
          hideProgressBar
          pauseOnHover
        />
      </ContactModalProvider>
    </Fragment>
  );
}

export default App;
