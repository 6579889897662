export const PALETTE = {
  bittersweet: '#ff6262',
  black: '#000000',
  blueRibbon: '#0d71eb',
  dodgerBlue: '#2286ff',
  gallery: '#ececec',
  hawkesBlue: '#edf4fe',
  mercury: '#e1e1e1',
  mineShaft: '#2b2b2b',
  tundora: '#444444',
  violet: '#1d0f4f',
  white: '#ffffff',

  get primary() {
    return this.blueRibbon;
  },
};
