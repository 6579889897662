import { PALETTE } from '@core/constants';
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  html,
  body {
    padding: 0;
    margin: 0;
    font-family: 'Heebo', sans-serif;
    font-weight: 400;
    font-size: 16px;
  }

  :root {
    --site-width: 1512px;
    --z-index-high: 100;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  a:focus {
    outline: 1px solid ${PALETTE.primary};
    outline-offset: 4px;
  }

  *:before,
  *:after {
    box-sizing: inherit;
  }
`;
